import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import "./reporte.css";
import Alert from "../../modal/alert";

class Reporte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo1: "",
      codigo3: "",
      codigo4: "",
      verplanillaOn: false,
      nalumno: [],
    };
  }

  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  limpiar = () => {
    this.setState({
      fecha: "",
      ifechamedi: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo1: "",
      codigo3: "",
      codigo4: "",
      verplanillaOn: false,
      nalumno: [],
    });
  };

  buscarTipoReporte = () => {
    if (this.state.tbusqueda === "1") this.reporteInv();
    if (this.state.tbusqueda === "2") this.reporteInvFac();
    if (this.state.tbusqueda === "3") this.reporteInvFacExcel();
    if (this.state.tbusqueda === "4") this.reporteNEPagadas();
    if (this.state.tbusqueda === "5") this.reporteNEPagadasFacturadas();
    if (this.state.tbusqueda === "6") this.reporteNEPagadasNoFacturadas();
    if (this.state.tbusqueda === "7") this.reporteTerapiasFac();
    if (this.state.tbusqueda === "8") this.reporteFacturaCitas();
    // if (this.state.tbusqueda === "3") this.pagosxMes();
    // if (this.state.tbusqueda === "4") this.ventasxMesTabla();
    // if (this.state.tbusqueda === "5") this.alumnosxSeccion();
    // if (this.state.tbusqueda === "6") this.canjesMes();
    // if (this.state.tbusqueda === "7") this.veranitoXEdad();
    // if (this.state.tbusqueda === "8") this.gradoSeccion();
  };

  reporteFacturaCitas = () => {
    if (this.state.codigo1.length > 0 && this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("dia", this.state.codigo1);
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteReciboFechaCita.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar Dia, Mes y el Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  reporteTerapiasFac = () => {
    if (this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteTerapiasFacturadas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar el Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  reporteInvFacExcel = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteInventarioFacturadoExcel.php",
        responseType: "arraybuffer",
        data: data,
      }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", "InventarioFacturado.xlsx"); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();
      });
      // .then(function (response) {
      //   let blob = new Blob([response.data], { type: "multipart/form-data" });
      //   var fileURL = URL.createObjectURL(blob);
      //   window.open(fileURL);
      // });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteNEPagadasFacturadas = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteNEPagadasFacturadas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  reporteNEPagadasNoFacturadas = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteNEPagadasNoFacturadas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  reporteNEPagadas = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteNEPagadas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  reporteInvFac = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteInventarioFacturado.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  reporteInv() {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteInventario.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  }

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  componentDidMount() {
    this.cargarFechas();
  }

  ax = () => {};
  render() {
    //console.log(this.state.token)
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago1 col-12">
          <div className="titulo-pago ">
            <p>MENU PARA GENERAR REPORTES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          <div className="contenedorp row">
            <div className="titulo-planillai col-12">
              <p>SELECCIONE EL TIPO DE REPORTE A EJECUTAR. </p>
            </div>
            <div className="row">
              <div className="fechas col-12">
                <select
                  id="tbusqueda"
                  name="tbusqueda"
                  value={this.state.tbusqueda}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Buscar Reporte por --</option>
                  {this.props.usuario1.nivel !== "2" ? (
                    <option value="1">Reporte de Inventario</option>
                  ) : (
                    ""
                  )}
                  {this.props.usuario1.nivel !== "2" ? (
                    <option value="2">Reporte de Inventario Facturado</option>
                  ) : (
                    ""
                  )}
                  {this.props.usuario1.nivel !== "2" ? (
                    <option value="4">Reporte de N/Entregas Pagadas</option>
                  ) : (
                    ""
                  )}
                  {this.props.usuario1.nivel !== "2" ? (
                    <option value="5">Reporte de N/E Pagadas Facturadas</option>
                  ) : (
                    ""
                  )}
                  {this.props.usuario1.nivel !== "2" ? (
                    <option value="6">Reporte de N/E Pagadas No Facturadas</option>
                  ) : (
                    ""
                  )}
                  {this.props.usuario1.nivel !== "2" ? (
                    <option value="7">Reporte de Terapias Facturadas</option>
                  ) : (
                    ""
                  )}
                  {this.props.usuario1.nivel !== "2" ? (
                    <option value="8">Reporte Citas Facturadas x Fecha</option>
                  ) : (
                    ""
                  )}
                  {/* {this.props.usuario1.nivel !== "2" ? <option value="2">Reporte morosos solo Nombres</option> :''}
                    {this.props.usuario1.nivel !== "2" ? <option value="3">
                      Reporte Detallado ingresos y creditos por mes
                    </option> :''}
                    {this.props.usuario1.nivel !== "2" ? <option value="4">
                      Consulta General de Ingresos en el Mes
                    </option> : ''}
                    <option value="5">Reporte Alumnos por salón</option>
                    {this.props.usuario1.nivel !=="2" ? <option value="6">Reporte de Canjes en el Mes</option> :''}
                    {this.props.usuario1.nivel !=="2" ? <option value="7">
                      Reporte de Veranito por Mes y Edad
                    </option> :''}
                    {this.props.usuario1.nivel !== "2" ? <option value="8">Reporte por Grado y Sección</option> :''} */}
                </select>
              </div>
            </div>
            {this.state.tbusqueda === "2" && (
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            {this.state.tbusqueda === "3" && (
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            {this.state.tbusqueda === "4" && (
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}

            {this.state.tbusqueda === "5" && (
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}

            {this.state.tbusqueda === "6" && (
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            {this.state.tbusqueda === "7" && (
              <div className="row">
                <div className="fechas col-12">
                <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            {this.state.tbusqueda === "8" && (
              <div className="row">
                <div className="fechas col-12">
                <select
                      id="codigo1"
                      name="codigo1"
                      value={this.state.codigo1}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Dia--</option>
                      {this.state.dia.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}

            <div className="xcontenedorcedula1 col-12">
              <button
                className="boton"
                onClick={this.buscarTipoReporte.bind(this)}
              >
                Consultar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Reporte);
