import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";

//componentes
import "./cierreRe.css";
import Alert from "../../modal/alert";

class CierreTrimestre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pasoOn: false,
      fecha: moment().format("YYYY-MM-DD"),
      mensajealerta: false,
      tituloMensaje: "",
      cuerpoMensaje: "",
      transferencia: "",
      deposito: "",
      tarjetaV: "",
      tarjetaC: "",
      efectivo: "",
      cheque: "",
      otrosP: "",
      canjes: "",
      total: "",
      tipoReporteCierre: "",
    };
  }

  limpiar = () => {
    this.setState({
      pasoOn: false,
      fecha: moment().format("YYYY-MM-DD"),
      mensajealerta: false,
      tituloMensaje: "",
      cuerpoMensaje: "",
      transferencia: "",
      deposito: "",
      tarjetaV: "",
      tarjetaC: "",
      efectivo: "",
      cheque: "",
      otrosP: "",
      canjes: "",
      total: "",
      tipoReporteCierre: "",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  imprimirCierre = () => {
    if (
      this.state.fecha.length > 0 &&
      this.state.tipoReporteCierre.length > 0
    ) {
      let data = new FormData();
      data.append("fecha", this.state.fecha);

      if (this.state.tipoReporteCierre === "1") {
        axios({
          method: "post",
          url:
            "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/informeRecepcion.php",
          responseType: "arraybuffer",
          data: data,
        }).then(function (response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
      }
      if (this.state.tipoReporteCierre === "2") {
        axios({
          method: "post",
          url:
            "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/informeRecepcionMensualAcumulado.php",
          responseType: "arraybuffer",
          data: data,
        }).then(function (response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
      }
      if (this.state.tipoReporteCierre === "3") {
        axios({
          method: "post",
          url:
            "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/informeRecepcionMensualXDia.php",
          responseType: "arraybuffer",
          data: data,
        }).then(function (response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
      }
      
    }
  };

  imprimirCierreFS = () => {
    if (this.state.fecha.length > 0) {
      let data = new FormData();
      if (this.props.usuario1.nivel === "12")
        data.append("claveUsu", this.props.usuario1.password);
      if (this.props.usuario1.nivel === "1")
        data.append("claveUsu", "FSERVI01");
      data.append("fecha", this.state.fecha);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/informeRecepcionFS.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  montoCierre = () => {
    if (this.state.fecha.length > 0) {
      let data = new FormData();
      data.append("fecha", this.state.fecha);
      data.append("boton", "cierreMontos");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          this.setState({
            pasoOn: true,
            transferencia: res.data.variable1,
            deposito: res.data.variable2,
            tarjetaV: res.data.variable3,
            tarjetaC: res.data.variable4,
            efectivo: res.data.variable5,
            cheque: res.data.variable6,
            otrosP: res.data.variable9,
            canjes: res.data.variable8,
            total: res.data.variable7,
          });
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consulta Diaria de Montos.",
        cuerpoM: "Debe existir una fecha verifique, gracias.",
      });
    }
  };

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CIERRE DE FACTURAS TEMPORALES EMITIDAS.</p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-4">
                    <input
                      className="form-control"
                      type="date"
                      name="fecha"
                      id="fecha"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.fecha}
                    />
                  </div>
                  <div className="contenedorcedula1f col-5">
                    <select
                      id="tipoReporteCierre"
                      name="tipoReporteCierre"
                      value={this.state.tipoReporteCierre}
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="">Tipo de Reporte.</option>
                      <option value="1">Por Dia</option>
                      <option value="2">Mensual Acumulado</option>
                      <option value="3">Mensual Diario</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="botones col-12">
                  <div className="acciones">
                    {/* <button
                      className="boton"
                      onClick={this.montoCierre.bind(this)}
                    >
                      Consultar
                    </button> */}
                    {this.props.usuario1.nivel !== "12" && (
                      <button
                        className="boton"
                        onClick={this.imprimirCierre.bind(this)}
                      >
                        Imprimir
                      </button>
                    )}
                    {/* {this.props.usuario1.nivel !== "12" && (
                      <button
                        className="boton"
                        onClick={this.imprimirCierreFS.bind(this)}
                      >
                        Imprimir Cierre Terapias
                      </button>
                    )}
                    {this.props.usuario1.nivel === "12" && (
                      <button
                        className="boton"
                        onClick={this.imprimirCierreFS.bind(this)}
                      >
                        Imprimir Cierre Terapias
                      </button>
                    )} */}
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                  </div>
                </div>
              </div>
              {this.state.pasoOn && (
                <div className="cont-listmedix row">
                  <div className="listcxcx col-12">
                    <div className="row">
                      <div className="conteni">{this.state.transferencia}</div>
                    </div>
                    <div className="row">
                      <div className="conteni">{this.state.deposito}</div>
                    </div>
                    <div className="row">
                      <div className="conteni">{this.state.tarjetaV}</div>
                    </div>
                    <div className="row">
                      <div className="conteni">{this.state.tarjetaC}</div>
                    </div>
                    <div className="row">
                      <div className="conteni">{this.state.efectivo}</div>
                    </div>
                    <div className="row">
                      <div className="conteni">{this.state.cheque}</div>
                    </div>
                    <div className="row">
                      <div className="conteni">{this.state.otrosP}</div>
                    </div>
                    <div className="row">
                      <div className="conteni">{this.state.canjes}</div>
                    </div>
                    <div className="row">
                      <div className="conteni">{this.state.total}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CierreTrimestre);
